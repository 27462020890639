@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --white: #fff;
  --dark-white: #F3F5F6;
  --blue: #01539e;
  --light-blue: #0054b8;
  --orange: #F5821F;
  --yellow: #feb900;
  --black: #4a4a4a;
}

#citys-listbox {
  font-size: 1.6rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 54.5%;
}

body {
  padding-right: 0 !important;
  overflow: auto !important;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

.p-0 {
  padding: 0 !important;
}

.p-2 {
  padding: 3.2rem;
}

.m-0 {
  margin: 0 !important;
}

.mt-0{
  margin-top: 0 !important;
}
.mb-0{
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 1.6rem !important;
}

.mt-1 {
  margin-top: 1.6rem !important;
}

.mb-2 {
  margin-bottom: 2.4rem !important;
}

.mt-2 {
  margin-top: 2.4rem !important;
}

.mb-3 {
  margin-bottom: 3.6rem !important;
}

.mt-3 {
  margin-top: 3.6rem !important;
}

.mb-4 {
  margin-bottom: 4.8rem !important;
}

.mt-4 {
  margin-top: 4.8rem !important;
}

.center {
  text-align: center !important;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-100 {
  width: 100%;
}

.justify-items-center {
  justify-items: center;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.margin-center {
  margin: 0 auto;
}

.colums-1 {
  display: grid;
  grid-template-columns: 1fr;
}

.colums-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.colums-3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.colums-4 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.gap-3 {
  gap: 10rem;
}
.gap-2 {
  gap: 5rem;
}
.gap-1 {
  gap: 2.5rem;
}

.desktop {
  display: flex !important
}

.mobile {
  display: none !important;
}

main {
  width: 100%;
  height: 100%;
  background-color: var(--blue);
  color: var(--white);
  position: relative;
  font-family: Arial, sans-serif;
  margin-top: 6.4rem;
}

.inputItem label{
  font-size: 1.9rem;
}

.title {
  font-style: normal;
  font-weight: 700;
  font-size: 5.2rem;
  line-height: 5.2rem;
  color: var(--white);
}

.subtitle {
  font-family: 'Source Sans 3', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 3.2rem;
  color: var(--white);
  margin-top: 2.4rem;
}

.divider {
  margin: 3.6rem 0 !important;
  background-color: #ffffff4d;
  display: block;
  margin: 0;
  width: 100%;
  border-top-width: .1rem;
  border-top-style: solid;
  border-top-color: #0000001f;
}

.titleInput {
  font-family: 'Source Sans 3', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3rem;
  text-align: start;
  color: var(--white);
  margin-bottom: 1.2rem;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1.2rem;
}

.input {
  background-color: var(--white);
  border-radius: 1rem;
}

.input label {
  font-size: 1.6rem;
  opacity: 0;
}

.input input {
  font-size: 1.6rem;
}

.textUnder {
  font-family: 'Source Sans 3', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2rem;
  text-decoration-line: underline;
  color: var(--white);
}

.destaque {
  background-color: #f9edc7;
  padding: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.destaque p {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 600;
  color: var(--black);
}

.blue {
  color: var(--blue) !important;
}

.light-blue {
  color: var(--light-blue) !important;
}

.largeText {
  margin-top: 2.4rem;
  font-size: 2.4rem;
  color: var(--black);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 4rem;
  height: 100%;
  text-align: center;
}

.imageInfo {
  display: block;
  height: auto;
  width: 90%;
}

.title-infos {
  letter-spacing: -.1rem;
}

.card-info p {
  text-align: center;
  font-size: 1.8rem;
  letter-spacing: 0;
  line-height: 2.4rem;
  color: #fff;
}

.card-info .subtitle {
  font-weight: 900;
  font-size: 3.4rem;
  letter-spacing: -.2px;
  margin-bottom: .8rem;
}

.card-info figure {
  height: 12.8rem;
  width: 12.8rem;
  margin: 0 auto;
}

.card-info img {
  display: block;
  height: auto;
  width: 100%;
}

.card {
  display: block;
  width: 100%;
  height: auto;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  border: .1rem solid #ccc;
  background-color: #fff;
  margin: 1rem;
}

.blue {
  color: blue;
}


.rede-exclusiva .title {
  font-weight: 900;
  font-size: 4.8rem;
  letter-spacing: .72px;
}

.rede-exclusiva .info {
  text-align: center;
  letter-spacing: 0;
  color: var(--black);
  opacity: 1;
  font-size: 2rem;
}

.rede-exclusiva .subtitle {
  letter-spacing: .9px;
  font-weight: 900;
  font-size: 8.5rem;
  margin-bottom: 2.4rem;
}

.rede-exclusiva .name {
  font-weight: 600;
  color: var(--black);
  font-size: 2.4rem;
}

.card-depoimento {
  text-align: center;
  width: 32rem;
  padding: 2rem;
  margin: 0;
  background-color: var(--white);
  box-shadow: 2px 2px 20px 1px #0003;
  border-radius: 2.5rem;
  display: flex;
  flex-direction: column;
  align-items: start;
  text-align: start;
}

.card-depoimento .subtitle {
  letter-spacing: 0;
  font-weight: 900;
  font-size: 3.4rem;
  color: var(--light-blue);
}

.card-depoimento .city {
  letter-spacing: 0;
  color: var(--light-blue);
  font-size: 2rem;
  font-weight: 900;
  margin-bottom: 1.6rem;
}

.card-depoimento .text {
  letter-spacing: 0;
  color: var(--black);
  font-size: 2rem;
}

.card-duvida .title {
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 3.2rem;
  letter-spacing: -.58px;
  text-align: start;
  text-shadow: 0px 3px 6px #00000029;
}

.card-duvida .text {
  font-size: 1.8rem;
  letter-spacing: 0;
}

.title-duvida {
  font-size: 5.4rem;
  font-weight: 400;
}

.container-facil {
  padding: 6rem;
}

.Toastify__toast-body > div:last-child{
  font-size: 1.6rem;
}

.content-form{
  display: block;
  background: rgba(0, 0, 0, 0.5);
  background-image: url(https://i.ibb.co/yycLYCw/Design-sem-nome-13.png);
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  border-style: none;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0px;
  border-radius: 0px;
  width: 100%;
  height: 75.2rem;
  position: relative;
}

.formulario{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  padding: 2rem 8rem;
  height: 100%;
}

.text-price{
  font-size: 1.6rem;
  color: #F5821F;
}
.price{
  font-size: 5.4rem;
  line-height: 4.6rem;
  color: #F5821F;
}
.infos{
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 6.4rem;
  text-transform: uppercase;
}
.button-wpp{
  padding: 1.2rem 2.4rem;
  font-size: 1.8rem;
  background-color: #F5821F;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: .8rem;
  font-weight: 500;
  border-radius: 3rem;
  /* margin: 0 auto; */
}

@media(max-width:732px) {
  .content-form{
    display: flex;
    flex-direction: column;
    gap: 3.6rem;
    width: 100%;
    grid-template-columns: 1fr;
    height: auto;
  }
  .formulario{
    padding: 2.4rem 4.8rem;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .textUnder{
    text-align: center;
  }
  .infos{
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .cbp_tmtimeline > li .cbp_tmicon{
    float: left !important;
    left: 10px !important;
    margin: -60px 5px 0 0px !important;
  }

  .container-duvida {
    padding: 0 !important;
  }

  .content {
    padding: 0;
  }

  .desktop {
    display: none !important;
  }

  .mobile {
    display: flex !important;
  }

  .margin-center {
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .w-80 {
    width: 100%;
  }

  .colums-1,
  .colums-2,
  .colums-3,
  .colums-4 {
    grid-template-columns: 1fr !important;
  }

  .imageInfo {
    width: 60%;
  }

  .margin-center {
    text-align: center;
  }

  .card-info .subtitle {
    font-size: 4.8rem !important;
    line-height: 5rem;
  }

  .card-info p {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }

  .container-facil {
    padding: 3rem;
  }

  .destaque p {
    font-size: 2.4rem;
  }

  .largeText {
    font-size: 3.2rem;
  }

  .rede-exclusiva .info {
    margin-bottom: 0 !important;
    font-size: 3.2rem;
  }

  .rede-exclusiva .name {
    font-size: 3.6rem;
  }

  .card-depoimento {
    width: 90%;
    text-align: center;
    margin-bottom: 2.4rem;
  }

  .card-depoimento .subtitle,
  .card-depoimento .city {
    text-align: center;
    margin: 0 auto;
  }

  .card-depoimento .subtitle {
    font-size: 5.4rem !important;
  }

  .card-depoimento .city {
    font-size: 3.4rem;
  }

  .card-depoimento .text {
    font-size: 2.8rem;
  }

  .cbp_tmtimeline>li .cbp_tmlabel p {
    font-size: 2.8rem !important;
  }

  .cbp_tmtimeline>li .cbp_tmicon {
    position: relative;
    float: left;
    left: auto;
    margin: -63px 1px 0px 8px;
  }

  .cbp_tmtimeline>li .cbp_tmtime {
    width: 100%;
    font-size: 4.8rem !important;
    position: relative;
    padding: 0 0 20px 0;
  }

  .card-duvida .title {
    font-size: 3.8rem;
    line-height: 3.8rem;
  }

  .card-duvida .text {
    font-size: 2.8rem;
    letter-spacing: 0;
  }

  .floating {
    bottom: .5rem !important;
    right: .5rem !important;
  }
  .pop-up {
    width: auto !important;
  }
}