.floating {
  position: fixed;
  right: 3rem;
  bottom: 2.5rem;
  z-index: 10;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  grid-gap: 1rem;
  gap: 1rem;
  z-index: 999;
}

.floating a{
  background-color: #F14668;
  color: white;
  border: none;
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  font-size: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 20px 1px #0003;
  cursor: pointer;
  transition: .2s ease-in-out;
}
.floating a:hover{
  background-color: #f05674;
}

.floating-simule{
  position: fixed;
  bottom: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.pop-up{
  display: flex;
  width: 50%;
  box-shadow: 2px 2px 20px 1px #0003;
  gap: 2.4rem;
  align-items: center;
  padding: 1.6rem 3.2rem;
  justify-content: space-between;
  font-size: 2.4rem;
  font-weight: 600;
  background-color: var(--dark-white);
  color: var(--black);
  border-radius: .8rem;
}


.btn-wpp{
  z-index: 9999999;
}