.footer {
  background-color: #F2F5F9;
  padding: 6rem 4rem;
  color: var(--black);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 4.8rem !important;
}

.containerButtons {
  display: flex;
  gap: 1.6rem;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.9rem;
  height: 3.9rem;
  padding: .8rem;
  border-radius: 50%;
  background-color: #000;
  color: var(--white);
  transition: background .2s ease-in-out;
}

.button:hover {
  background-color: var(--black);
}

.button svg {
  font-size: 2rem;
}

.logoFooter {
  max-width: 25rem;
  margin: 0 auto;
  width: 100%;
  object-fit: cover;
}

.first {
  gap: 2.4rem;
}

.infos p {
  font-size: 1.8rem;
  font-weight: 500;
  margin-bottom: 1.2rem;
}

.infos p strong {
  font-weight: 600;
  color: #000;
}

.contentFooter {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.contentFooter h3 {
  color: var(--light-blue) !important;
  font-weight: 700 !important;
}

.last{
  margin: 0 auto;
}

@media(max-width:865px) {
  .footer {
    grid-template-columns: 1fr 1fr;
  }
  .logoFooter{
    width: 75%;
    margin: 0 auto;
  }
}

@media(max-width:732px) {
  .footer {
    grid-template-columns: 1fr;
  }

  .button {
    width: 6rem;
    height: 6rem;
  }

  .button svg {
    font-size: 3rem;
  }

  .infos p {
    font-size: 2.8rem;
    text-align: start;
  }

  .contentFooter h3 {
    font-size: 4.8rem;
    line-height: 5rem;
    text-align: center;
  }

  .last {
    align-items: center;
  }
}