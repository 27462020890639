.button {
  background-color: #fff;
  border-color: #dbdbdb;
  border-width: .1rem;
  color: #363636;
  cursor: pointer;
  justify-content: center;
  font-size: 1.6rem;
  padding: .8rem 1.6rem;
  text-align: center;
  white-space: nowrap;
  border-radius: 3rem;
  background-color: #f14668;
  border-color: transparent;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
}