.container {
  width: 100%;
  height: calc(100vh - 6.4rem);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 6rem;
  background-repeat: no-repeat;
  background-size: cover;
}

.bgWhite {
  background-color: var(--white);
  color: var(--black);
}

.bgDarkWhite {
  background-color: var(--dark-white);
  color: var(--black);
}

.bgOrange {
  background-color: var(--orange);
  color: var(--white);
}

.bgLightBlue {
  background-color: #037BC5;
  color: var(--white);
}

.hAuto {
  height: auto;
}

.noPadding {
  padding: 0 !important;
}



@media(max-width:732px) {
  .container {
    padding: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}