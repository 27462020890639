.header {
  background-color: var(--blue);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 4px #3c3c3c;
  width: 100%;
  padding: .8rem 3.6rem;
  height: 6.4rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
}

.header ul {
  display: flex;
}

.header li {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .8rem 1.2rem;
  line-height: 2rem;
  font-size: 1.6rem;
}

.header li button {
  font-size: 1.6rem !important;
  color: var(--white) !important;
  line-height: 2rem;
  text-transform: none;
}

.options ul {
  background-color: var(--light-blue);
}

.option {
  color: var(--white) !important;
  font-size: 1.6rem !important;
  display: flex;
  gap: .8rem;
  background-color: var(--light-blue);
}

.menuMobile{
  width: 100vw;
  height: 100vh;
  background-color: var(--blue);
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(250rem);
  transition: transform .5s ease-in-out;
}

.buttonClose{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 3.2rem;
  font-size: 1.6rem;
  border-radius: 50%;
  border: none;
  position: absolute;
  font-weight: 900;
  right: 3rem;
  top: 1rem;
  cursor: pointer;
}

.active{
  transform: translateX(0);
}

.menuMobile ul{
  flex-direction: column;
  padding: 3.2rem 5.6rem;
  gap: 2.4rem;
}
.menuMobile ul li{
  font-size: 2.4rem;
  height: 5.5rem;
  background-color: var(--light-blue);
  border-radius: 1.5rem;
  box-shadow: 5px 5px 15px rgba(0,0,0,.08);
  transition: background .2s ease-in-out;
}
.menuMobile ul li:hover{
  background-color: #0e62c9;
}
.menuMobile ul li:last-child{
  font-size: 2.4rem;
  height: 5.5rem;
  border: none;
  background-color: transparent;
  box-shadow: none;
}
.menuMobile ul li button{
  font-size: 2.4rem !important;
}
.mobile {
  display: none;
  font-size: 3.5rem;
  cursor: pointer;
}

.desktop {
  display: flex;
}

@media(max-width:869px) {
  .desktop {
    display: none !important;
  }

  .mobile {
    display: flex !important;
  }
}