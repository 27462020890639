.containerCard {
  display: flex;
  flex-direction: column;
  justify-content: start;
  height: 100%;
  padding: 2.4rem;
}

.containerCard img{
  width: 100%;
  height: auto;
}


.yellow {
  color: #fdb813;
}

.orange {
  color: #ff5917;
}

.yellow {
  color: var(--yellow);
}

.white {
  color: var(--white);
}

.black {
  color: var(--black);
}

.blue {
  color: #003D85;
}


.city{
  color: var(--blue);
  font-size: 2.5rem;
  font-weight: 400;
}

.name{
  font-weight: 900;
  color: var(--blue);
  font-size: 3rem;
  line-height: 3rem;
}

.street{
  font-size: 2.5rem;
  color: var(--black);
  letter-spacing: 0;
}

.containerCard {
  opacity: 0.5;
}

.containerCard.active {
  opacity: 1;
}


@media(max-width:732px) {
  .containerCard img{
    width: auto;
    object-fit: cover;
    height: 25rem;
  }

}