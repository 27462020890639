.containerSlide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 19rem 0 19rem;
  height: 100%;
}

.title {
  font-size: 4.8rem;
  color: #fff;
  line-height: 5rem;
  font-weight: 900;
  margin-bottom: 1.6rem;
}

.subtitle {
  font-size: 3.4rem;
  line-height: 4rem;
  font-weight: 700;
}

.text {
  font-size: 2rem;
  color: var(--white);
}

.yellow {
  color: #fdb813;
}

.orange {
  color: #ff5917;
}

.yellow {
  color: var(--yellow);
}

.white {
  color: var(--white);
}

.black {
  color: var(--black);
}

.blue {
  color: #003D85;
}

.flexCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bgBlue {
  background-color: #003D85;
  height: 100%;
  flex-direction: row !important;
  display: flex;
  padding: 3rem 13rem 0 13rem;
  gap: 1.8rem;
}

.contentCenter{
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.bgWhite {
  background-color: var(--dark-white);
}

.bgTimao {
  background-image: url('https://contrate-online.hapvida.com.br/assets/images/agenciacorinthians.png');
  background-repeat: no-repeat;
  background-size: cover;
  display: grid;
  grid-template-columns: 1fr 23rem;
  padding: 3rem 13rem 0 13rem;
}

.bgTimao img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.bigTitle {
  font-size: 9.6rem;
  font-weight: 900;
  line-height: 6rem;
}

.contentImage {
  display: flex;
  align-items: start;
  height: 100%;
}

.card {
  box-shadow: 0 0.5em 1em -0.125em #0a0a0a1a, 0 0 0 1px #0a0a0a05;
  padding: .8rem 2.4rem;
  width: 13rem;
  border-radius: .8rem;
  height: 6.5rem;
}

.card img {
  width: auto;
  height: 100%;
}

.imgCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card h3 {
  font-weight: 900;
  font-size: 2.2rem;
  line-height: 2.4rem;
  text-align: end;
}

.card .top {
  text-align: start !important;
}

.card .center {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center !important;
}

.alignCenter {
  width: 100%;
  align-items: center;
  justify-items: center;
}
.contentTimao{
  width: 100%;
}

@media(max-width:732px) {
  .bgTimao {
    grid-template-columns: 1fr;
    padding: 1rem;
  }

  .containerSlide {
    flex-direction: column;
    display: flex;
    padding: 6rem;
    height: 100%;
    justify-content: center;
  }
  .alignCenter{
    grid-template-columns: 1fr 1fr !important;
  }
  .bgTimao img{
    margin: 0 auto;
    width: 50%;
    height: auto;
  }
  .bigTitle{
    font-size: 6.5rem;
  }
  .title{
    font-size: 3.6rem;
  }
  .subtitle{
    font-size: 2.8rem;
  }
  .bgBlue{
    flex-direction: column !important;
    align-items: center !important;
    width: 100%;
  }
}